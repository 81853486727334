import { useMemo } from 'react'
import { ETableColumn } from '../../../enums/ETableColumn'
import FormatTime from '../columns/formatTime'
import ImageColumn from '../columns/image'
import OperationColumn from '../columns/operation'
import SwitchColumn from '../columns/switch'
import { ITableProProps, NCustomTable } from '../const'
import styles from '../index.module.less'

export default function useTableColumns(props: ITableProProps) {
  const { size } = props

  const tablePadding = useMemo(() => {
    const width = size === 'small' ? styles.tablePaddingHorizontalsm : size === 'middle' ? styles.tablePaddingHorizontalmd : styles.tablePaddingHorizontal
    const height = size === 'small' ? styles.tablePaddingVerticalsm : size === 'middle' ? styles.tablePaddingVerticalmd : styles.tablePaddingVertical
    return { width: parseInt(width), height: parseInt(height) }
  }, [props.size])

  /**
   * 处理表格列props
   */
  function getColumnProps(column: NCustomTable.Column) {
    let render = column.render
    if (column.type) {
      column = enhanceColumn(column)
      render = (value, record, index) => renderColumn({ value, record, index, column })
    }

    // 复写render函数
    if (render) {
      column.render = (value, record, index) => {
        return render!.apply(null, [
          value,
          record,
          index,
          (value) => {
            if (column.dataIndex) {
              record[column.dataIndex as string] = value
            }
            props.onTableOperationClick?.({ record, index, operationIndex: -1, type: 'Custom', operationType: column.operationType })
          }
        ])
      }
    }
    return column
  }

  // 列组件
  function renderColumn(data: { value: any; record: any; index: number; column: any }) {
    return (
      {
        [ETableColumn.Operation]: <OperationColumn {...data} onTableOperationClick={props.onTableOperationClick} />,
        [ETableColumn.Switch]: <SwitchColumn {...data} onSwitchChange={() => props.onTableChange && props.onTableChange()} />,
        [ETableColumn.Image]: <ImageColumn {...data} />,
        [ETableColumn.TimeFormat]: <FormatTime {...data} />,
        [ETableColumn.RichText]: <div dangerouslySetInnerHTML={{ __html: data.value }} />,
        [ETableColumn.Link]: (
          <span className={styles.columnLink} onClick={() => data.column.href && data.column.href(data)}>
            {data.value}
          </span>
        )
      } as any
    )[data.column.type]
  }

  // 表格列增强
  const enhanceColumn = (column: NCustomTable.Column) => {
    const { type } = column
    // 操作列处理
    if (type === ETableColumn.Operation) {
      column.title = column.title || '操作'
    }
    // 图片处理
    if (type === ETableColumn.Image) {
      const { width, height } = column
      width && (column.width = width + tablePadding.width * 2)
      height && (column.height = height + tablePadding.height * 2)
        ; (column as any)._tablePadding = tablePadding
    }

    return column
  }

  return { getColumnProps }
}
