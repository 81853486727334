import { message, Spin } from 'antd'
import dayjs from 'dayjs'
import instance from '~/request/instance'
import { getDays } from './date'
import { export_array_to_excel, export_json_to_excel } from './excel'
/**
 * 是否为图片格式
 */
export const checkIsImage = (fileName: string) => {
  const reg = /\.(png|jpg|gif|jpeg|webp)$/
  return reg.test(fileName)
}

/**
 * 是否为oss链接视频
 */
export const isFilterFileUrlVideo = (fileUrl) => {
  const suffixArr = (fileUrl + '')?.split('.')
  const fileSuffix = suffixArr[suffixArr.length - 1]
  const videoList = ['mp4', 'rmvb', 'avi', 'flv', 'm2v', 'mkv', 'wmv', 'mp3', 'wav']
  if (videoList.indexOf(fileSuffix) > -1) {
    console.log('是视频及音频文件')
    return true
  }
  return false
}

export const sizeConversionMb = (size) => {
  return Math.floor(size / 1024 / 1024)
}

/**
 @params
isPictureCompress: false, //是否开启图片压缩
pictureQuality: 0.92, //指定压缩的图片质量,取值范围为0~1,quality值越小,图像越模糊,默认图片质量为0.92
 compressThreshold: 5,  //压缩的阈值,图片大小超过5M,则需要进行压缩

*/

//在上传之前转换文件
export const transformFile = (file, isPictureCompress = false, pictureQuality = 0.92, compressThreshold = 0) => {
  return new Promise<any>((resolve, reject) => {
    /**
     * 针对图片进行压缩,如果图片大小超过压缩阈值,则执行压缩,否则不压缩
     */
    //判断是否是图片类型
    if (checkIsImage(file.name)) {
      console.log(file.name)
      let spinLoading = false
      let fileSize = file.size / 1024 / 1024
      // console.log('before compress, the file size is : ', fileSize + "M");
      //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
      console.log(fileSize, '原始尺寸M')
      if (fileSize >= compressThreshold && isPictureCompress) {
        //判断浏览器内核是否支持base64图片压缩
        if (typeof FileReader === 'undefined') {
          return resolve(file)
        } else {
          try {
            spinLoading = true
            asyncReaderFile(file, pictureQuality)
              .then((res) => {
                spinLoading = false
                return resolve(res)
              })
              .catch(() => {
                spinLoading = false
                return resolve(file)
              })
          } catch (e) {
            spinLoading = false
            //压缩出错,直接返回原file对象
            return resolve(file)
          }
        }
      } else {
        //不需要压缩，直接返回原file对象
        return resolve(file)
      }
    } else {
      //非图片文件,不进行压缩,直接返回原file对象
      return resolve(file)
    }
  })
}

export const asyncReaderFile = (file, pictureQuality) => {
  return new Promise((resolve, reject) => {
    //声明FileReader文件读取对象
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      // 生成canvas画布
      const canvas = document.createElement('canvas')
      // 生成img
      const img: any = document.createElement('img')
      img.src = reader.result
      img.onload = () => {
        const ctx: any = canvas.getContext('2d')
        //原始图片宽度、高度
        let originImageWidth = img.width,
          originImageHeight = img.height
        //默认最大尺度的尺寸限制在（1920 * 1080）
        let maxWidth = 375,
          maxHeight = 204,
          ratio = maxWidth / maxHeight
        //目标尺寸
        let targetWidth = originImageWidth,
          targetHeight = originImageHeight
        //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
        if (originImageWidth > maxWidth || originImageHeight > maxHeight) {
          //超过最大宽高比例
          if (originImageWidth / originImageHeight > ratio) {
            //宽度取最大宽度值maxWidth,缩放高度
            targetWidth = maxWidth
            targetHeight = Math.round(maxWidth * (originImageHeight / originImageWidth))
          } else {
            //高度取最大高度值maxHeight,缩放宽度
            targetHeight = maxHeight
            targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight))
          }
        }
        // canvas对图片进行缩放
        canvas.width = targetWidth
        canvas.height = targetHeight
        // 清除画布
        ctx.clearRect(0, 0, targetWidth, targetHeight)
        // 绘制图片
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight)
        // quality值越小,图像越模糊,默认图片质量为0.92
        const imageDataURL: any = canvas.toDataURL(file.type || 'image/jpeg', pictureQuality)
        // 去掉URL的头,并转换为byte
        const imageBytes = window.atob(imageDataURL.split(',')[1])
        // 处理异常,将ascii码小于0的转换为大于0
        const arrayBuffer = new ArrayBuffer(imageBytes.length)
        const uint8Array = new Uint8Array(arrayBuffer)
        for (let i = 0; i < imageBytes.length; i++) {
          uint8Array[i] = imageBytes.charCodeAt(i)
        }
        let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1]
        let newFile = new File([uint8Array], file.name, { type: mimeType || 'image/jpeg' })
        console.log('lrzFile : ', newFile.size / 1024 / 1024 + 'M')
        resolve(newFile)
      }
    }
    reader.onerror = () => {
      reject(file)
    }
  })
}

export function loadXslx(data = [], sheetName = 'Sheet1', excelName = '未命名') {
  // base64固定头部
  let uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
  // 拼接数据,其实就是个table的格式,可以根据喜欢的方式拼接,也可以传入喜欢的数据格式
  let final = ''
  Object.keys(data[0])?.map((item) => {
    final += `<td>${item}</td>`
  })
  final = `<tr>${final}</tr>`
  data?.map((item) => {
    let row = ''
    Object.values(item)?.map((val) => {
      row += `<td>${val}</td>`
    })
    row = `<tr>${row}</tr>`
    final += row
  })
  const template = `
    <html
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40">
      <head>
        <meta charset="UTF-8">
        <!--[if gte mso 9]>
          <xml>
            <x:ExcelWorkbook>
              <x:ExcelWorksheets>
                <x:ExcelWorksheet>
                  <x:Name>${sheetName}</x:Name>
                  <x:WorksheetOptions>
                    <x:DisplayGridlines />
                  </x:WorksheetOptions>
                </x:ExcelWorksheet>
              </x:ExcelWorksheets>
            </x:ExcelWorkbook>
          </xml>
        <![endif]-->
        <style type="text/css">  
        .bg-gray {
          background: #aaa;
          }
          .bg-gray td {
             text-align: left;
          }
          th,td {
            text-align: center;
            mso-number-format:\@;
            vnd.ms-excel.numberformat:\@;
          }
        </style>

      </head>
      <body>
        <table>
          ${final}
        </table>
      </body>
    </html>`
  // 这部分处理的比较简单，可以参考fileSaver的那篇进行优化处理
  let URL = uri + window.btoa(unescape(encodeURIComponent(template)))

  let link = document.createElement('a')
  //对下载的文件命名
  link.download = excelName
  link.href = URL
  link.click()
  return true
}

/**
 @params
  data: [], //表格数据
  table: , // table实例
  headerTitle: string,  //标题
*/

export function editDatatable(data = [], table, headerTitle, contentMap = []) {
  const { columns } = table
  const fieldKeys: any = []
  const newDataTable: any = [] //excel表格数据
  // 表格title
  columns?.map((col) => {
    if (col.dataIndex) {
      fieldKeys.push({ title: col.title, dataIndex: col.dataIndex })
    }
  })
  data?.map((item) => {
    let row = {}
    // 根据列表dataIndex生成对应的表头属性
    fieldKeys.map((key) => {
      row[key.title] = item[key.dataIndex] ?? ''
      if (key.title.includes('时间')) {
        row[key.title] = (item[key.dataIndex] && dayjs(item[key.dataIndex]).format('YYYY-MM-DD HH:mm:ss')) || '--'
      }
      if (key.dataIndex.includes('No')) {
        row[key.title] = item[key.dataIndex] + '\t'
      }

      if (key.title === '领取时间') {
        row[key.title] = (item[key.dataIndex] && dayjs(item[key.dataIndex]).format('YYYY-MM-DD HH:mm:ss')) || '未领奖'
      }
      contentMap?.map(({ key: label, value }) => {
        if (key.dataIndex === label) {
          row[key.title] = value[Number(item[key.dataIndex]) || 0] || '/'
        }
      })
    })
    newDataTable.push(row)
  })
  console.log('newDataTable  ---->>>> ', newDataTable)
  loadXslx(newDataTable, headerTitle, headerTitle)
}

/**
 @params
  table: , // table实例
  headerTitle: string,  //标题
  requestUrl:string  // 接口地址
  queryParams: any 请求参数
*/

interface IReduceDataList {
  table: any
  title: string
  url: string
  queryParams: any
  contentMap: []
}

/**
 * 异步并发分页调用，然后拼接数据
 */
export const reduceDataList = async ({ table, title, url, queryParams, contentMap }: IReduceDataList) => {
  //pageSize最大只有100，是需要根据分页循环调用拼接数据的
  // const resData = await instance.get(url, { ...queryParams, pageSize: 100 })
  const resData = await instance.get(url, { ...queryParams })
  const { pages } = resData.data
  let dataList: any = []
  if (pages > 1) {
    dataList = await Promise.all(
      [...Array(pages).keys()].map(async (page) => {
        if (page) {
          // const { data } = await instance.get(url, { ...queryParams, pageSize: 100, pageNum: page + 1 })
          const { data } = await instance.get(url, { ...queryParams, pageNum: page + 1 })
          return data.list
        } else {
          return resData?.data?.list
        }
      })
    )
    //数组转换
    dataList = dataList.reduce((a, b) => {
      return a.concat(b)
    })
  } else {
    dataList = resData?.data?.list
  }

  if (!dataList?.length) {
    message.info('暂无数据')
    return
  }

  /**
   * 处理试用报告 queryStatus ，后端微返回具体枚举，前端做的条件判断
   */
  const contentMapFind = contentMap?.findIndex((find) => find?.key === 'queryStatus')
  if (contentMapFind !== -1) {
    const queryStatusList = dataList?.map((row) => {
      const { receiveStatus } = row
      row.queryStatus = queryStatusType(row)
      row.receiveStatus = receiveStatus === -1 ? 2 : receiveStatus
      return row
    })
    return editDatatable(queryStatusList, table, title, contentMap)
  }

  editDatatable(dataList, table, title, contentMap)
}

interface IContentMap {
  key: string
  value?: any
  renderValue?: Function
}

interface INoTableReduceDataList {
  title: string
  url: string
  queryParams: any
  pageSize: number
  responseList?: any
  columns?: object[]
  contentMap?: IContentMap[]
}
/**
 * 非table表格直接导出，调研模版,同步循环调用
 */
export const directlyReduceDataList = async ({
  title,
  url,
  queryParams,
  pageSize,
  responseList = [],
  columns = [],
  contentMap = []
}: INoTableReduceDataList) => {
  //pageSize最大只有100，是需要根据分页循环调用拼接数据的
  const resData = await instance.get(url, { ...queryParams, pageSize })
  const { pages, hasNextPage, pageNum, list } = resData?.data
  let newList: any = []
  //后端未处理好数据，根据columns配置导出,
  if (columns && columns.length > 0) {
    list?.map((element: any) => {
      const listCell = []
      columns?.forEach((item, index) => {
        const { dataIndex, title } = item
        if (dataIndex?.includes('time') || title?.includes('时间') || dataIndex?.includes('gmtCreate')) {
          element[dataIndex] = getDays(element[dataIndex])
        }
        contentMap?.forEach((row: IContentMap) => {
          const { key, value, renderValue = null } = row
          if (dataIndex === key) {
            if (renderValue) {
              element[dataIndex] = renderValue(element)
            } else {
              element[dataIndex] = value[element[dataIndex]] || '-'
            }
          }
        })
        listCell.push(element[dataIndex] !== undefined ? element[dataIndex] : '-')
      })

      newList.push(listCell)
    })
  } else {
    // 直接后端数据处理好数据导出
    newList = newList.concat(list)
  }

  responseList = responseList.concat(newList)
  if (hasNextPage) {
    return directlyReduceDataList({
      title,
      url,
      queryParams: { ...queryParams, pageNum: pageNum + 1 },
      pageSize,
      responseList,
      columns
    })
  }

  const titleList: string[] = []
  if (columns && columns.length > 0) {
    columns?.forEach((item: any) => {
      titleList.push(item?.title)
    })
  }

  if (!responseList?.length) {
    message.info('暂无导出数据')
    return
  }

  export_json_to_excel({
    key: [],
    data: responseList,
    title: titleList,
    filename: title,
    autoWidth: true
  })
  message.destroy()
  message.success('导出成功')
}

export const queryStatusType = (row) => {
  let string = 0
  const { feedbackStatus, auditStatus } = row
  if (feedbackStatus == false) {
    string = 0
  } else {
    if (auditStatus == 0) {
      string = 1
    }
    if (auditStatus == 1) {
      string = 2
    }
    if (auditStatus == -1) {
      string = 3
    }
  }

  return string
}

/**
 * 下载文件
 */
export const downFileHandler = (url, fileName) => {
  var x = new XMLHttpRequest()
  x.open('GET', url, true)
  x.responseType = 'blob'
  x.onload = function (e) {
    var url = window.URL.createObjectURL(x.response)
    var a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
  }
  x.send()
}

/**
 * 含枚举类型表格，同步导出
 */
interface IEnumTableDataList {
  table: any
  title: string
  url: string
  queryParams: any
  contentMap: []
  responseList?: any
}
export const exportEnumTableList = async ({ table, title, url, queryParams, contentMap, responseList = [] }: IEnumTableDataList) => {
  try {
    const resData = await instance.get(url, { ...queryParams })
    const { pages, hasNextPage, pageNum } = resData?.data
    responseList = responseList.concat(resData?.data?.list)
    if (hasNextPage) {
      return exportEnumTableList({
        table,
        title,
        url,
        queryParams: { ...queryParams, pageNum: pageNum + 1 },
        contentMap
      })
    }
    return editEnumDatatable(responseList, table, title, contentMap)
  } catch (error) {
    return false
  }
}
const editEnumDatatable = (data = [], table, headerTitle, contentMap = []) => {
  const { columns } = table
  const fieldKeys: any = []
  const newDataTable: any = [] //excel表格数据
  // 表格title
  columns?.map((col) => {
    if (col.dataIndex) {
      fieldKeys.push({ title: col.title, dataIndex: col.dataIndex })
    }
  })
  data?.map((item) => {
    let row = {}
    // 根据列表dataIndex生成对应的表头属性
    fieldKeys.map((key) => {
      row[key.title] = item[key.dataIndex] ?? ''
      contentMap?.map(({ key: label, value }) => {
        if (key.dataIndex === label) {
          row[key.title] = value[item[key.dataIndex]] || '/'
        }
      })
    })
    newDataTable.push(row)
  })
  return loadXslx(newDataTable, headerTitle, headerTitle)
}
