export const isDevelopment = process.env.NODE_ENV === 'development'

export const requestBaseUrl = 'community' // 接口域名路径

export const apiUrl = retBaseUrl()
export const aliyunOssTokenUrl = `/xy/console/oss/policy`

export const defaultPwd = 'ocj123456' // 默认密码

export const publicPath = '/'

export const routeType = 'hash' // 路由类型 history | hash

/**
 * 是否跳过权限验证
 * @warning 允许在接口无法调用时继续本地开发.只允许在本地开发时使用
 */
export const skipAuth = process.env.NODE_ENV === 'development'

function retBaseUrl() {
  try {
    if (isDevelopment) return '/'
    const { host } = globalThis.location
    if (host.includes('dev')) {
      return 'http://live.xysiyu.com/'
      // return 'https://api.dev.xinc818.net/'
    }
    if (host.includes('daily')) {
      // return 'https://api.daily.xinc818.net/'
      return 'http://live.xysiyu.com/'
    }
    if (host.includes('gray')) {
      // return 'https://api.gray.xinc818.net/'
      return 'http://live.xysiyu.com/'
    }
    // return 'https://api.xinc818.com/'
    return 'http://live.xysiyu.com/'
  } catch (err) {
    // return 'https://api.xinc818.com/'
    return 'http://live.xysiyu.com/'
  }
}

/**
 * 打包构建 环境变量
 */
const REACT_APP_ENV: any = process.env.REACT_APP_ENV

/** cdn 域名 */
export const cdnHost = REACT_APP_ENV === 'production' || REACT_APP_ENV === 'gray' ? 'https://coremall.oss-cn-shanghai.aliyuncs.com' : 'https://coremall.oss-cn-shanghai.aliyuncs.com'
