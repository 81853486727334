import { aliyunOssTokenUrl } from '~/config'
import { notification } from 'antd'
import { getResizeUrl } from '~/modules/@xy/aliyun'
import AliYunWeb from '~/modules/@xy/aliyun/src/webForm'
import request from '~/request/instance'
import instance from '~/request/instance'
import { transformFile } from '~/utils/file'
import { promises } from 'dns'

const { upload: uploadAliyun } = new AliYunWeb({
  getOssToken: async () => await instance.get(aliyunOssTokenUrl, { dir: 'mall/' })
  // request({ url: aliyunOssTokenUrl })
})

async function upload(fileList: File[]) {
  try {
    // 图片压缩
    const larList: File[] = await Promise.all(
      fileList.map(async (file: File) => {
        return await transformFile(file, true, 0.3)
      })
    )
    console.log(larList, 'larList')
    const res = await uploadAliyun(fileList)
    return res
  } catch (e) {
    notification.error({ message: '阿里云上传失败', description: e.message })
    return Promise.reject(e)
  }
}

type MockImageType =
  | 'banner' // 轮播
  | 'good' // 商品
  | 'head' // 头像
  | 'big' // 大图
  | 'icon' // 图标
  | 'member_bg' // 个人中心头部背景图
  | 'listTpl' // 列表模板
  | 'category_tpl' // 分类模板
  | 'store' // 店铺

/**
 *  * 获取阿里云测试图片地址
 *
 * @export
 * @param {MockImageType} type 图片类型
 * @param {{ width?: number, height?: number }} [size] 裁剪信息
 * @param {number|string} [index] 指定第几张图片或是图片名称
 * @returns 图片地址
 */
function getOssMockImage(type: MockImageType, size?: { width?: number; height?: number }, index?: number | string) {
  const BASE_URL = 'https://wmm-mock.oss-cn-shanghai.aliyuncs.com/mock/'
  const max = {
    banner: 4,
    goods: 9,
    head: 4,
    big: 4,
    icon: 9,
    member_bg: 7
  }

  let num: any
  if (index === undefined) {
    num = parseInt(`${Math.random() * (max[type] + 1)}`, 10)
  } else {
    num = index
  }

  return `${BASE_URL}${type}${num}.png${size ? getResizeUrl(size) : ''}`
}

export { upload, getOssMockImage }
