import { FC, memo } from 'react'
import styles from './index.module.less'
import { ICityPickerProps } from './const'
import { Cascader } from 'antd'
import options from '../../assets/json/district.json'

const Component: FC<ICityPickerProps> = (props) => {
  // 通过解构定义defaultProps
  const { onChange, ...rest } = props

  // const handleChange = (value, selectedOptions) => {
  //   // console.log(value, selectedOptions)
  //   onChange && onChange(value, selectedOptions)
  // }

  return <Cascader {...props} options={options} />
}

Component.displayName = 'CityPicker'

const CityPicker = memo(Component)
export default CityPicker
