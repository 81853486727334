import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.sensitiveKeys,
    component: () => import('../../pages/sensitiveKeys/sensitiveKeys/list'),
    meta: { title: '敏感词库' }
  }
] as TRoutes[]
