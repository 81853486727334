export enum ETableOperation {
  /** 编辑 */
  Edit = 'Edit',
  /** 删除 */
  Delete = 'Delete',
  /** 自定义操作类型 */
  Custom = 'Custom',
  /** 置顶 */
  Top = 'Top',
  /** 点赞 */
  Like = 'Like',
  /** 评论 */
  Comment = 'Comment',
  /** 收藏 */
  Collection = 'Collection',
  /** 取消收藏 */
  CancelCollection = 'CancelCollection',
  /** 精选 */
  Handpick = 'Handpick',
  /** 取消精选 */
  CancelHandpick = 'CancelHandpick'
}
