import { Input, InputProps } from 'antd'
import { FC, memo } from 'react'
import styles from '../index.module.less'

export interface LimitInputProps extends Omit<InputProps, 'suffix'> {}

const Component: FC<LimitInputProps> = (props) => {
  const { value = '', maxLength = 12 } = props
  return (
    <Input
      {...props}
      suffix={
        <span className={styles.textTip}>
          {(value as string).length}/{maxLength}
        </span>
      }
    />
  )
}

Component.displayName = 'LimitInput'

const LimitInput = memo(Component)
export default LimitInput
