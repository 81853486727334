import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
    {
        path: routeNames.commonProblemClassification,
        component: () => import('../../pages/customerServiceManagement/commonProblemClassification'),
        meta: { title: '常见问题分类' }
    },
    {
        path: routeNames.commonProblemManagement,
        component: () => import('../../pages/customerServiceManagement/commonProblemManagement'),
        meta: { title: '常见问题列表' }
    },
    {
        path: routeNames.addProblem,
        component: () => import('../../pages/customerServiceManagement/commonProblemManagement/addProblem'),
        meta: { title: '常见问题', hideInMenu: true }
    },
    {
        path: routeNames.workOrderManagement,
        component: () => import('../../pages/customerServiceManagement/workOrderManagement'),
        meta: { title: '工单管理' }
    },
    {
        path: routeNames.workOrderDetail,
        component: () => import('../../pages/customerServiceManagement/workOrderManagement/workOrderDetail'),
        meta: { title: '工单详情', hideInMenu: true }
    }
] as TRoutes[]