import { cdnHost } from '~/config'
import { merge, autobind } from '~/modules/@xy/decorator/src/components'
import { guid } from '~/modules/@xy/utils/src/other'

/**
 * web端使用form表单形式上传文件
 */
@autobind
export default class AliYunWebForm {
  private getOssToken: () => Promise<any>

  constructor(config: { getOssToken: () => Promise<any> }) {
    this.getOssToken = config.getOssToken
  }

  /**
   * 上传文件
   *
   * @param {string[]} fileList
   * @returns
   * @memberof AliYun
   */
  async upload(fileList: File[]) {
    const {
      data: { accessid, signature, policy, dir, host }
    } = await this.getOssTokenMerge()
    // const reg = /^https/;

    return Promise.all(
      fileList.map(
        (file) =>
          new Promise((resolve, reject) => {
            const { name } = file

            const key = `${dir}${guid()}.${name.substr(name.lastIndexOf('.') + 1)}`
            const form = new FormData()
            form.append('name', 'file')
            form.append('signature', signature)
            form.append('OSSAccessKeyId', accessid)
            form.append('policy', policy)
            form.append('key', key)
            form.append('success_action_status', '200')
            form.append('file', file)
            //稀选图片转发
            const IMG_URL = 'https://static.xinc818.com'

            fetch(host, { method: 'post', body: form, mode: 'cors' })
              // .then(() => resolve(`${(process.env.NODE_ENV === 'production' && IMG_URL) || host}/${key}`))
              .then(() => resolve(`${cdnHost}/${key}`))
              .catch((error) => {
                reject(error)
              })
          })
      )
    )
  }

  @merge()
  private getOssTokenMerge() {
    return this.getOssToken()
  }
}
