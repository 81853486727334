import { createBrowserHistory, createHashHistory } from 'history'
import { generateRouteAndMenu, IRoute, routeNames } from './const'
import BasicLayout from '~/layouts/basicLayout'
import MMPNOMatch from '~/pages/404'
import MMPNoAuth from '~/pages/403'

import _example from './modules/_example'
import departmentManagement from './modules/departmentManagement'
import taskActivityManagement from './modules/taskActivityManagement'
import customerServiceManagement from './modules/customerServiceManagement'
import circleManagement from './modules/circleManagement'
import Login from '../pages/login'
import { routeType } from '~/config'
import sensitiveKeys from './modules/sensitiveKeys'
import operatingCopywriter from './modules/operatingCopywriter'
import userManagement from './modules/userManagement'

import messageManage from './modules/messageManage'
import {
  WarningOutlined,
  AppstoreOutlined,
  CustomerServiceFilled,
  CommentOutlined,
  MessageOutlined,
  UserOutlined,
  ScheduleOutlined,
  TrophyOutlined
} from '@ant-design/icons'
export const history = routeType === 'hash' ? createHashHistory() : createBrowserHistory()

export const routers: IRoute[] = [
  { path: routeNames.login, component: Login, meta: { title: '登录', sync: true, noLogin: true } },
  {
    path: '/',
    layout: BasicLayout,
    redirect: true,
    children: [
      { name: '敏感词库', icon: WarningOutlined, children: sensitiveKeys },
      { name: '运营文案管理', icon: ScheduleOutlined, children: operatingCopywriter },
      { name: '用户管理', icon: UserOutlined, children: userManagement },
      { name: '留言讨论管理', icon: MessageOutlined, children: messageManage },
      { name: '直播管理', icon: TrophyOutlined, children: taskActivityManagement },
      { name: '圈子管理', icon: CommentOutlined, children: circleManagement },
      { name: '客户服务', icon: CustomerServiceFilled, children: customerServiceManagement },
      { name: '权限管理', icon: AppstoreOutlined, children: departmentManagement },
      { path: routeNames[403], component: MMPNoAuth, meta: { title: '没有权限', sync: true, noLogin: true } },
      { path: '*', component: MMPNOMatch, meta: { sync: true, noLogin: true } }
    ]
  }
]

const { routes } = generateRouteAndMenu(routers)

export function getMenus() {
  return generateRouteAndMenu(routers).menus
}

export { routes }
