import { FC, memo, useMemo } from 'react'
import { Image } from 'antd'
import { NCustomTable } from '../../const'
import getComponentProps from '../../utils/getComponentProps'
// import styles from './index.module.less'

export interface IProps extends NCustomTable.IColumnProps<NCustomTable.IImageColumn> { }

const Component: FC<IProps> = (props) => {
  const { value = '' } = props
  let imgProps = getComponentProps(props)

  const styleProps = useMemo(() => {
    const tablePadding = (props as any)._tablePadding || {}
    const { width, height } = props.column
    return {
      width: width ? width - tablePadding.width * 2 : 120,
      height: height ? height - tablePadding.height * 2 : 120
    }
  }, [props.column])

  return !!value ? <Image {...imgProps} {...styleProps} src={value} /> : null
}

Component.displayName = 'ImageColumn'

const ImageColumn = memo(Component)
export default ImageColumn
