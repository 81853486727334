import { Table } from 'antd'
import { FC, ReactNode, useEffect } from 'react'
import { Modal, Spin } from 'antd'
import { Store } from 'antd/lib/form/interface'
import useFormPro from '~/modules/@xy/backend-pro/src/hooks/useFormPro'
import FormPro from '~/modules/@xy/backend-pro/src/components/formPro'
import { MallConfHotKeyDto } from '~/request/data-contracts'
import useCompositionTable, { IUseCompositionTable } from '~/modules/@xy/backend-pro/src/hooks/useCompositionTable'
import CompositionModalTable from '~/components/compositionModalTable'
import CompositionTable from '~/modules/@xy/backend-pro/src/components/compositionTable'
import { api } from '~/request'

interface IProps {
  /** 标题名 */
  title?: string | ReactNode
  /** 弹窗宽高 */
  width?: number | string
  visible: boolean
  loading: boolean
  confirmLoading: boolean
  onFinish: () => Promise<void>
  open: (initData?: Store | (() => Promise<any>)) => Promise<void>
  close: () => void
  formPro: ReturnType<typeof useFormPro>
  table?: any
}

const EditFormModalPage: FC<IProps> = (props) => {
  let { formPro, table } = props

  useEffect(() => {
    if (props.visible) {
      if (table) {
        table.queryTableData({})
      }
    } else {
      if (table) {
        table = null
      }
    }
  }, [props.visible])

  return (
    <Spin spinning={props.loading}>
      <Modal
        style={{}}
        className="edit-form-modal-page"
        width={props.width}
        title={props.title}
        visible={props.visible}
        confirmLoading={props.confirmLoading}
        destroyOnClose={true}
        forceRender // 强制渲染 和 关闭清除子组件冲突
        keyboard={false}
        maskClosable={false}
        onOk={props.onFinish}
        onCancel={props.close}
        afterClose={formPro.formRef.current?.resetFields}
      >
        {table! && table!.columns!.length > 0 ? <CompositionTable {...table} /> : ''}

        <FormPro ref={formPro.formRef} {...props.formPro.option} />
      </Modal>
    </Spin>
  )
}

export default EditFormModalPage
