import { Space } from 'antd'
import { FC, memo, useState } from 'react'
import { ETableOperation } from '../../../../enums/ETableOperation'
import { NCustomTable } from '../../const'
import styles from './index.module.less'

export interface IProps<T = any> extends NCustomTable.IColumnProps<NCustomTable.IOperationColumn<T>> {
  onTableOperationClick?(options: ITableOperationClickOption<T>): void
}

export interface IOperations {
  /** 操作类型 */
  type: ETableOperation | keyof typeof ETableOperation
  /** column操作类型 */
  operationType?: string
  /** 文本 */
  text?: string
  /** 是否显示 */
  show?: boolean
  /** 扩展其余字段 */
  [i: string]: any
}

export interface ITableOperationClickOption<T = any> extends IOperations {
  /** 操作索引 */
  operationIndex: number
  /** 当前行数据 */
  record: T
  /** 当前行 */
  index: number
}

const Component: FC<IProps> = (props) => {
  const { column, record, index, onTableOperationClick } = props
  const [columnText] = useState({
    [ETableOperation.Edit]: '编辑',
    [ETableOperation.Delete]: '删除',
    [ETableOperation.Custom]: ''
  })

  function hanldeClick(operation: IOperations, operationIndex: number) {
    const options = { ...operation, operationIndex, record, index }
    if (column.onTableOperationClick) {
      column.onTableOperationClick(options)
    } else if (onTableOperationClick) {
      onTableOperationClick(options)
    }
  }

  const columns = column.operations({ column, record, index })

  return (
    <Space>
      {columns
        .filter((item) => item.show === undefined || item.show !== false)
        .map((operation, index) => {
          if (typeof operation.self_render === 'function') { // 2022-3-24 新增自定义操作 
            return operation.self_render(record)
          }
          return (
            <span
              key={operation.type + '-' + index + '-' + operation.text || ''}
              className={styles.operationItem}
              onClick={() => hanldeClick(operation, index)}
            >
              {operation.text || columnText[operation.type]}
            </span>
          )
        })}
    </Space>
  )
}

Component.displayName = 'OperationColumn'

const OperationColumn = memo(Component)
export default OperationColumn
