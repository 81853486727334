import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.publicSetting,
    component: () => import('../../pages/taskActivityManagement/publicSetting'),
    meta: { title: '公众号管理' }
  },
  {
    path: routeNames.liveAnchorList,
    component: () => import('../../pages/taskActivityManagement/anchorManagement/index'),
    meta: {
      title: '主播列表'
    }
  },
  {
    path: routeNames.liveBroadcastList,
    component: () => import('../../pages/taskActivityManagement/liveBroadcastList'),
    meta: {
      title: '直播列表'
    }
  },
  {
    path: routeNames.addLiveBroadcast,
    component: () => import('../../pages/taskActivityManagement/liveBroadcastList/addLiveBroadcast'),
    meta: {
      hideInMenu: true,
      title: '新增直播列表'
    }
  },
  {
    path: routeNames.editLiveBroadcast,
    component: () => import('../../pages/taskActivityManagement/liveBroadcastList/editLiveBroadcast'),
    meta: {
      hideInMenu: true,
      title: '编辑直播列表'
    }
  },
  {
    path: routeNames.viewLiveBroadcastDetail,
    component: () => import('../../pages/taskActivityManagement/liveBroadcastList/viewLiveBroadcastDetail'),
    meta: {
      hideInMenu: true,
      title: '编辑直播列表'
    }
  }
] as TRoutes[]
