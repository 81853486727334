import { useState, useRef } from 'react';
import { FormInstance } from 'antd/es/form';
import { Store } from 'antd/lib/form/interface';
import useFormPro from "~/modules/@xy/backend-pro/src/hooks/useFormPro"
import { IFormProProps } from '~/modules/@xy/backend-pro/src/components/formPro/const';

export interface IModalFormOption extends IFormProProps {
  submit?: (formData: any, rawData?: any) => Promise<any>
  cancel?: () => void
}

export function useEditFormModal(formInfo: IModalFormOption) {

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const rawData = useRef<Store>();
  const formPro = useFormPro(formInfo)
  
  const editForm = formPro.formRef.current;
  // FormPro把Form也封装进去了 所以只能通过这个函数模拟submit
  async function onFinish () {
    const formData = await editForm?.validateFields();

    setConfirmLoading(true);
    try {
      if (formInfo.submit) {
        await formInfo.submit(formData, rawData.current);
      }
      setVisible(false);
      setConfirmLoading(false);
    } catch (err) {
      setConfirmLoading(false);
    }
  }

  async function open (initData?: Store | (() => Promise<any>), title: string = '编辑') {
    rawData.current = initData;
    editForm?.resetFields();
    if ( typeof initData === 'function' ) {
      // 使用函数打开
      setLoading(true);
      const data = await initData();
      setLoading(false);
      rawData.current = data;
      editForm?.setFieldsValue(data);
    } else if ( initData ) {
      editForm?.setFieldsValue(initData);
    }
    setTitle(title);
    setVisible(true);
  }

  function close() {
    setVisible(false);
    formInfo.cancel && formInfo.cancel();
  }

  return {
    title,
    visible,
    loading,
    confirmLoading,
    onFinish,
    formPro,
    // 通过函数打开关闭弹窗
    open,
    close
  }
}