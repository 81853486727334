
/** 表格查询操作 */
export enum EQueryOperation {
  /** 新增 */
  Add,
  /** 查询 */
  Query,
  /** 重置 */
  Reset,
  /** 自定义操作类型 */
  Custom,
}