import { FC, memo } from 'react'
import { Input, Space } from 'antd'

export interface RangeInputProps {
  value?: [string, string]
  onChange?(data?: [string, string]): void
}

const Component: FC<RangeInputProps> = (props) => {
  const { value = [], onChange } = props

  const handleChagne = (val: string, index: number) => {
    if (onChange) {
      const vals: any = [...value]
      vals[index] = val
      onChange(vals)
    }
  }

  return (
    <Space split={'-'}>
      <Input value={value[0]} autoComplete="off" onChange={(ev) => handleChagne(ev.target!.value || '', 0)} />
      <Input value={value[1]} autoComplete="off" onChange={(ev) => handleChagne(ev.target!.value || '', 1)} />
    </Space>
  )
}

Component.defaultProps = {}
Component.displayName = 'RangeInput'

const RangeInput = memo(Component)
export default RangeInput
