import { cloneDeep } from 'lodash'
import { ISubMenu, IMenus, IBaseRouteMenu } from '~/data-model/common'

export const routeNames = {
  eTable: '/_example/table',
  eForm: '/_example/formPro',
  login: '/login',
  register: '/register',
  403: '/403',

  // 运营文案管理
  ...{
    aboutUs: '/operatingCopywriter/aboutUs', // 关于我们
    communityRules: '/operatingCopywriter/communityRules', // 社区规则
    privacyAgreement: '/operatingCopywriter/privacyAgreement', // 隐私协议
    operationPaperworkQualityTesting: '/operatingCopywriter/quality/testing', // 运营文案管理 / 质检公示管理
    operationPaperwork: '/operatingCopywriter/quality/testing/edit', // 运营文案管理 / 质检公示管理 / 质检公示 新增/修改
    articleSort: '/operatingCopywriter/articleSort' // 帖子排序设置
  },
  // 敏感词库
  sensitiveKeys: '/sensitiveKeys',
  ...{
    // 用户管理
    publicSetting: '/taskActivityManagement/publicSetting', //公众号设置
    userLevel: '/userManagement/userLevel/list', //用户等级
    userList: '/userManagement/userList/list', //用户列表
    userDetail: '/userManagement/userDetail/list', //用户详情
    userDetailIntegral: '/userManagement/userDetail/integral', //用户积分
    userDetailUp: '/userManagement/userDetail/up', //用户成长值
    userDetailInvitation: '/userManagement/userDetail/invitation', //用户邀请好友记录
    userDetailTags: '/userManagement/userDetail/tags', //标签信息
    unactivedUsers: '/userManagement/unactived/users', // 用户管理 / 未激活用户列表
    userGroup: '/userManagement/userGroup/list', // 用户分组
    userGroupBuild: '/userManagement/userGroup/build', // 用户管理 / 新建、编辑分组
    userGroupAdd: '/userManagement/userGroup/add', // 用户管理 / 新建、编辑分组 / 分组用户批量添加
    uriExchange: '/userManagement/uriExchange' // 小程序转链
  },
  ...{
    departmentManagement: '/departmentManagement',
    positionManagement: '/positionManagement',
    employeeManagement: '/employeeManagement',
    roleManagement: '/roleManagement',
    resourcesManagement: '/resourcesManagement',
    companyResourcesGroup: '/companyResourcesGroup',
    companyResourcesGroupEdit: '/companyResourcesGroup/edit'
  },
  ...{
    // settingBanner: '/taskActivityManagement/settingBanner',
    liveAnchorList: '/taskActivityManagement/liveAnchorList',
    liveBroadcastList: '/taskActivityManagement/liveBroadcastList',
    addLiveBroadcast: '/liveBroadcastList/addLiveBroadcast',
    editLiveBroadcast: '/liveBroadcastList/editLiveBroadcast',
    viewLiveBroadcastDetail: '/liveBroadcastList/viewLiveBroadcastDetail',
  },
  ...{
    commonProblemClassification: '/customerServiceFilled/commonProblemClassification',
    commonProblemManagement: '/customerServiceFilled/commonProblemManagement',
    addProblem: '/commonProblemManagement/addProblem',
    workOrderManagement: '/customerServiceFilled/workOrderManagement',
    workOrderDetail: '/workOrderManagement/workOrderDetail'
  },
  /**
   * 圈子管理
   */
  ...{
    // circleBanner: '/circleManagement/settingBanner',
    classifyManagement: '/circleManagement/classifyManagement',
    circleManagement: '/circleManagement/circleManagement',
    circleAudit: '/circleManagement/circleAudit',
    circleDetail: '/circleManagement/circleDetail',
    createCirclePermissionSettings: '/circleManagement/createCirclePermissionSettings',
    whiteListAdministrator: '/createCirclePermissionSettings/whiteListAdministrator',
    postsManagement: '/circleManagement/postsManagement',
    postsAudit: '/circleManagement/postsAudit',
    postsAuditDetail: '/circleManagement/postsDetail',
    postsDetail: '/postsManagement/postsDetail',
    createPosts: '/circleManagement/createPosts',
    newsNotice: '/circleManagement/newsNotice',
    topicManagement: '/circleManagement/topicManagement'
    // commentManagement: '/circleManagement/commentManagement'
  },
  // 留言讨论管理
  ...{
    anchorMessageList: '/messageManage/anchorMessageList', // 主播留言
    replyVideoList: '/messageManage/replyVideoList', // 回复视频
    whiteList: '/messageManage/whiteList' // 回复白名单管理
  }
}

export type RouteCode = string | string[]

/**
 * 菜单路由
 * 这个是用于配置那些路由应该显示在菜单内
 */
export interface MenuRoute extends IBaseRouteMenu {
  /** 菜单名称 */
  name: string
  /** 菜单权限code */
  code?: RouteCode
  /** 嵌套子级 */
  children?: IRoute[]
}

/**
 * 布局路由
 * 由于配置一些列的路由共享同一套布局
 */
export interface LayoutRoute {
  /** 布局名称 */
  name?: string
  /** 路径 */
  path?: string
  /** 布局组件 */
  layout: any
  /**
   * 页面转发
   * 设置为string表示跳转至指定路由
   * 设置为true表示跳转至菜单权限中第一个路由页面.当菜单有权限控制时这会非常有用
   */
  redirect?: string | boolean
  /** 嵌套子级 */
  children?: IRoute[]
}

/** 路径路由 */
export interface TRoutes extends IBaseRouteMenu {
  /** 路由路径 */
  path: string
  /** 路由组件 */
  component: any
  /** 路由元数据 */
  meta?: {
    /**
     * 标题
     * 用于菜单栏显示以及浏览器标签头部显示
     * */
    title?: string
    /** 页面权限code */
    code?: RouteCode
    /** 是否不显示在菜单栏 */
    hideInMenu?: boolean
    /**
     * 是否显示在菜单栏
     * 只有当你的路由是配置在顶级页面.无subMenu时.你才需要配置这个属性用户将他显示在菜单栏中
     * */
    showInMenu?: boolean
    /**
     * 是否是同步组件
     *
     * 默认情况下.路由都是基于页面级的code-split,并且在渲染过程总使用异步组件做了包裹
     * 如果说组件需要成为同步组件.那么你需要用sync进行显式的声明
     * */
    sync?: boolean
    /**
     * 是否不需要登录权限
     * 如果页面不需要登录验证.将此值设置为true
     * */
    noLogin?: boolean

    [i: string]: any
  }
  /** 嵌套路由 */
  children?: RLRoute[]
}

export type IRoute = MenuRoute | TRoutes | LayoutRoute

export type RLRoute = TRoutes | LayoutRoute

export interface IAuthSubMenu extends ISubMenu {
  code?: RouteCode
  children: (IAuthSubMenu | IAutMenu)[]
}

export interface IAutMenu extends IMenus {
  code?: RouteCode
}

export function isLayoutRoute(data: IRoute): data is LayoutRoute {
  return !!(data as LayoutRoute).layout
}

export function isMenuRoute(data: IRoute): data is MenuRoute {
  return !isLayoutRoute(data) && !!(data as MenuRoute).name
}

export function isRoute(data: IRoute): data is TRoutes {
  return !isLayoutRoute(data) && !isMenuRoute(data)
}

/**
 * 转换路由和菜单数据
 *
 */
export function generateRouteAndMenu(data: IRoute[]) {
  const menudata: (IAuthSubMenu | IAutMenu)[] = []

  function formatIRoute(data: IRoute[], menu?: IAuthSubMenu) {
    return data.reduce((results, item) => {
      if (isMenuRoute(item)) {
        const { children = [], name, ...rest } = item

        const subMenu: IAuthSubMenu = { ...rest, title: name, children: [] }
        if (menu) {
          menu.children.push(subMenu)
        } else {
          menudata.push(subMenu)
        }

        const childrenRoutes = formatIRoute(children, subMenu)
        results = results.concat(childrenRoutes)
      } else if (isLayoutRoute(item)) {
        if (item.children && item.children.length) {
          item.children = formatIRoute(item.children)
        }
        results.push(item)
      } else {
        const { meta = {}, path, icon, iconImg } = item
        const { hideInMenu, showInMenu, code } = meta
        const title = meta.title || path
        // 菜单赋值
        if (menu && !hideInMenu) {
          menu.children.push({ title, path, code, icon, iconImg })
        }
        if (showInMenu) {
          menudata.push({ title, path, code, icon, iconImg })
        }
        results.push(item)
      }

      return results
    }, [] as RLRoute[])
  }

  const routes = formatIRoute(cloneDeep(data))

  return {
    menus: menudata,
    routes
  }
}

function isAuthMenu(data: IAuthSubMenu | IAutMenu): data is IAutMenu {
  return !!(data as IAutMenu).path
}

/**
 * 是否有code
 *
 * @param {string[]} codes
 * @param {RouteCode} [code]
 * @return {*}
 */
function hasCode(codes: string[], code?: RouteCode) {
  return !code ? false : typeof code === 'string' ? codes.indexOf(code) !== -1 : !!codes.find((cs) => code.indexOf(cs) !== -1)
}

/**
 * 根据权限code过滤菜单栏
 *
 * @export
 * @param {((IAuthSubMenu | IAutMenu)[])} data 菜单数据
 * @param {string[]} [authCodes=[]] 权限code
 * @param {boolean} [skipAuth=false] 是否不检验code 用于开发环境跳过校验
 * @return {*}
 */
export function filterMenuByCode(data: (IAuthSubMenu | IAutMenu)[], authCodes: string[] = [], skipAuth = false) {
  return data.reduce((obj, item) => {
    if (isAuthMenu(item)) {
      const hasAuth = skipAuth || !item.code || hasCode(authCodes, item.code)
      if (hasAuth) {
        obj.push(item)
      }
    } else {
      let { code, children = [] } = item
      const hasAuth = skipAuth || !code || hasCode(authCodes, code)
      if (hasAuth) {
        if (children.length) {
          children = filterMenuByCode(children, authCodes, skipAuth)
        }

        if (children.length) {
          obj.push({ ...item, children })
        }
      }
    }

    return obj
  }, [] as (IAuthSubMenu | IAutMenu)[])
}
