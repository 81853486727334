import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.userList,
    component: () => import('../../pages/userManagement/userList/list'),
    meta: { title: '用户列表' }
  },
  {
    path: routeNames.userDetail,
    component: () => import('../../pages/userManagement/userDetail/list'),
    meta: {
      title: '用户详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.unactivedUsers,
    component: () => import('../../pages/userManagement/UnactivedUsers'),
    meta: { title: '未激活用户列表' }
  },
  {
    path: routeNames.userGroup,
    component: () => import('../../pages/userManagement/userGroup/list'),
    meta: { title: '用户分组' }
  },
  {
    path: routeNames.userGroupBuild,
    component: () => import('../../pages/userManagement/userGroup/build'),
    meta: {
      title: '新建分组',
      hideInMenu: true
    }
  },
  {
    path: routeNames.userGroupAdd,
    component: () => import('../../pages/userManagement/userGroup/add'),
    meta: {
      title: '批量添加',
      hideInMenu: true
    }
  },
  
  {
    path: routeNames.uriExchange,
    component: () => import('../../pages/userManagement/uriExchange'),
    meta: { title: '小程序转链' }
  }
] as TRoutes[]
