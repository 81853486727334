import React, { memo, useEffect, useRef, useState } from 'react'
import Editor from 'wangeditor'
import { ConfigType } from 'wangeditor/dist/config'
import { upload } from '~/components/aliyun'
import styles from './index.module.less'
// 自定义菜单 暂不需要
// import createCustomMenu from './createCustomMenu'

export type IRichTextConfig = Partial<Omit<ConfigType, 'onchange' | 'customUploadImg'>>

export interface IRichTextProps {
  value?: string
  onChange?: (value: string, txtValue: string) => void
  // 自定义配置项
  config?: IRichTextConfig,
  uploadVideo?: boolean //是否显示上传视频按钮
}

interface IAttribute {
  id: string
  isFocused: boolean
  outputHTML: string
  editor?: Editor,
}

function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}
function guid() {
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}

const defaultMenus = [
  'undo',
  'redo',
  'fontSize',
  'foreColor',
  'backColor',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'indent',
  'fontName',
  'lineHeight',
  'justify',
  'quote',
  'code',
  'splitLine',
  'link',
  'image'
]

/**
 * 默认配置项
 */
const defaultEditorConfig: IRichTextConfig = {
  menus: defaultMenus,
  // 允许全屏
  showFullScreen: true,
  // 编辑器z-index
  zIndex: 1,
  // 全屏时z-index
  zIndexFullScreen: 1000,
  // 是否过滤粘贴的样式
  pasteFilterStyle: true,
  // 不允许粘贴网络图片
  pasteIgnoreImg: true,
  // 上传图片配置
  showLinkImg: false,
  // 上传图片上限
  uploadImgMaxLength: 10,
  // 上传图片大小限制
  uploadImgMaxSize: 4 * 1024 * 1024, // 4M
  // 上传图片文件类型
  uploadImgAccept: ['jpg', 'jpeg', 'png', 'gif']
}

const RichText: React.FC<IRichTextProps> = (props) => {
  const [uploading, setUploading] = useState(false)
  const attribute = useRef<IAttribute>({
    id: 'f-' + guid(),
    isFocused: false,
    outputHTML: '',
    editor: undefined
  })

  function clear(html: string) {
    // 框架bug
    const newHtml = html.replace(new RegExp('style="background-color: rgb(255, 255, 255);"', 'gm'), '')
    return newHtml
  }

  useEffect(() => {
    // 仅无数据时才给更换
    if (!attribute.current.outputHTML && props.value) {
      attribute.current.editor?.txt.html(props.value)
    } else if (!props.value) {
      attribute.current.editor?.txt.html('')
    }
  }, [props.value])

  useEffect(() => {
    attribute.current.editor = new Editor('#' + attribute.current.id)
    const instance = attribute.current.editor
    if (!instance) {
      return
    }

    const { config = {} } = props

    if (props.uploadVideo) {
      defaultEditorConfig.menus = [...defaultMenus, 'video']
      instance.config.uploadVideoAccept = ['mp4', 'MP4']
      instance.config.customUploadVideo = async function (resultFiles: File[], insertVideoFn: Function) {
        if (Array.isArray(resultFiles) && resultFiles.length > 0) {
          const videoUrl = await upload(resultFiles)
          videoUrl.forEach((item) => {
            insertVideoFn(item)
          })
        }
      }
    }
    instance.config = { ...instance.config, ...defaultEditorConfig, ...config }
    // 自定义上传图片
    instance.config.customUploadImg = async function (resultFiles: File[], insertImgFn: Function) {
      if (Array.isArray(resultFiles) && resultFiles.length > 0) {
        const imgUrl = await upload(resultFiles)
        imgUrl.forEach((item) => {
          insertImgFn(item)
        })
      }
    }
    // 富文本转译
    instance.config.onchange = (newHtml: string) => {
      attribute.current.outputHTML = clear(newHtml)
      props.onChange?.(attribute.current.outputHTML, attribute.current.editor?.txt.text())
    }

    instance.create()
    if (props.value) {
      attribute.current.editor?.txt.html(props.value || '')
    }

    return () => {
      instance.destroy()
      attribute.current.editor = undefined
    }
  }, [])

  return (
    <div className={styles.brafEditor}>
      <div id={attribute.current.id} />
    </div>
  )
}

RichText.defaultProps = {}

export default memo(RichText)
