import { apiUrl, isDevelopment, requestBaseUrl } from '~/config'
import Request from '~/modules/@xy/request/src/index'
import { history } from '~/routes'
import { message, notification } from 'antd'
import { routeNames } from '~/routes/const'

const instance = Request.create({ baseUrl: apiUrl })

/**
 * 请求拦截器
 */
instance.requestInterceptors.use((config) => {
  const Authorization = window.localStorage.getItem('Authorization')
  if (Authorization) {
    config.headers = config.headers || {}
    config.headers.Authorization = Authorization
  }
  config.url = config.url.replace('xy/', '')
  console.log('请求报文', config)
  return config
})

/**
 * 响应拦截器
 */
instance.responseInterceptors.use((res) => {
  const { status, data = {}, url } = res
  let description: string | null = null
  console.log('响应报文', data, status)
  if (status === 403 || data.code === 403) {
    message.warn(data.msg || '无权访问该资源')
    description = ''
  } else if (data.code === 401 || status === 401) {
    const target = window.location.pathname

    if (!/\/login\?/.test(target)) {
      history.replace({ pathname: routeNames.login, state: { from: target } })
    }
    description = ''
  } else if (data.code === 50000) {
    description = data.msg
    // notification.error({ message: '请求错误', description: data.msg })
  } else if (status === 200 && data.code !== 0) {
    description = data.msg
  } else if (status < 200 || status >= 300) {
    if (data.error) {
      description = data.error.message
    } else if (status && status > 0) {
      description = `url:${url},statusCode:${status}`
    } else if (status && status === -1) {
      description = '网络请求失败，请检查您的网络。'
    } else {
      description = '未知错误，万分抱歉！'
    }
  }
  if (description !== null) {
    if (description) {
      notification.error({ message: '请求错误', description })
    }
    throw new Error()
  }

  return res
})

// 提取接口请求中的res.data
instance.responseInterceptors.use(({ data }) => data)

export default instance
