import { Row, Col, Form, Input, Select, DatePicker, Button, Cascader } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Search from 'antd/lib/input/Search'
import { Dayjs } from 'dayjs'
import { FC, memo, useEffect, useMemo } from 'react'
import { EQueryOperation } from '../../enums/EQueryOperation'
import { EQueryType } from '../../enums/EQueryType'
import RangeInputNumber from '../rangeInputNumber'
import { IQueryItem, IQueryOperation, IQueryProps, IQuerySelectItem } from './const'
import styles from './index.module.less'
import RangeInput from './RangeInput'

const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker

const getKey = (key: string | [string, string]) => (key instanceof Array ? key.join(',') : key)

const Component: FC<IQueryProps> = (props) => {
  const { gutter = 15, query = [], value = {} } = props

  const [inside_form] = useForm()
  const form = props.form || inside_form

  const cols = useMemo(() => {
    return (query instanceof Function ? query() : query).filter((item) => !!item)
  }, [query])

  useEffect(() => {
    if (value) {
      form.setFieldsValue(value)
    }
  }, [value])

  const handleOperationClick = (item: IQueryOperation, index: number) => {
    if (props.onQueryOperationClick) {
      if (item.type === EQueryOperation.Reset) {
        form.resetFields()
      }
      getAndFormatValue().then((values) => {
        props.onQueryOperationClick!(item, index, values)
      })
    }
  }

  const getAndFormatValue = () => {
    let queryParams: Record<string, any> = {}
    return form
      .validateFields()
      .then((params) => {
        queryParams = Object.keys(params).reduce((obj, key) => {
          let value = params[key]
          if (value === undefined || value === null) {
            return obj
          }
          // 获取type
          const col = cols.find((item) => getKey(item.key) === key)!

          if (col.type === EQueryType.DatePicker) {
            const { format = 'YYYY-MM-DD' } = col.itemProps || {}
            // value = (value as Dayjs).format(format as string)
            obj[key] = value
          } else if (col.type === EQueryType.DatePickerRange) {
            const { format = 'YYYY-MM-DD HH:mm:ss' } = col.itemProps || {}
            const [start, end] = value.map((va: Dayjs, index) =>
              index === 0 ? va.startOf('date').format(format as string) : va.endOf('date').format(format as string)
            )
            obj[col.key[0]] = start
            obj[col.key[1]] = end
          } else if ([EQueryType.RangeInput, EQueryType.RangeInputNumber].includes(col.type as EQueryType)) {
            const [name1, name2] = col.key
            obj[name1] = value[0]
            obj[name2] = value[1]
          } else {
            obj[key] = typeof value === 'string' ? value.trim() : value
          }
          return obj
        }, queryParams)
        return queryParams
      })
      .catch(() => queryParams)
  }

  const parseQueryFormValue = (result: Record<string, any>) => {
    let queryParams: Record<string, any> = {}
    return Object.keys(result).reduce((obj, key) => {
      let value = result[key]
      if (value === undefined || value === null) {
        return obj
      }
      // 获取type
      const col = cols.find((item) => getKey(item.key) === key)!

      if (col.type === EQueryType.DatePicker) {
        const { format = 'YYYY-MM-DD' } = col.itemProps || {}
        // value = (value as Dayjs).format(format as string)
        obj[key] = value
      } else if (col.type === EQueryType.DatePickerRange) {
        const { format = 'YYYY-MM-DD HH:mm:ss' } = col.itemProps || {}
        const [start, end] = value.map((va: Dayjs, index) =>
          index === 0 ? va.startOf('date').format(format as string) : va.endOf('date').format(format as string)
        )
        obj[col.key[0]] = start
        obj[col.key[1]] = end
      } else if ([EQueryType.RangeInput, EQueryType.RangeInputNumber].includes(col.type as EQueryType)) {
        const [name1, name2] = col.key
        obj[name1] = value[0]
        obj[name2] = value[1]
      } else {
        obj[key] = value
      }

      return obj
    }, queryParams)
  }

  const renderSelect = (item: IQuerySelectItem) => (
    <Select placeholder={`请选择${item.title}`} allowClear {...item.itemProps}>
      {item.options &&
        item.options.length &&
        item.options.map((opt) => (
          <Option {...(opt as any)} key={opt.value}>
            {opt.label || opt.title}
          </Option>
        ))}
    </Select>
  )

  const renderItem = (item: IQueryItem) => {
    const aItem: any = item
    const { title } = aItem
    const baseOptions = {
      placeholder: `请输入${title || ''}`
    }

    if (item.type === EQueryType.Operation) {
      return (
        <div style={{ marginBottom: 24 }}>
          <Item noStyle>
            {(item.operations as IQueryOperation[])
              .filter((item) => !!item && item.show !== false)
              .map((operation, index) => (
                <Button
                  key={operation.title + operation.type}
                  type={operation.type === EQueryOperation.Query ? 'primary' : undefined}
                  {...operation.itemProps}
                  onClick={() => handleOperationClick(operation, index)}
                  className={styles.operation_button}
                >
                  {operation.title}
                </Button>
              ))}
          </Item>
        </div>
      )
    } else if (item.type === EQueryType.SearchInput) {
      return (
        <div style={{ marginBottom: 24 }}>
          <Item noStyle>
            <Search
              enterButton="搜索"
              {...baseOptions}
              onSearch={(value) => {
                props.onQuerySearch && props.onQuerySearch({ [item.key]: value })
              }}
            />
          </Item>
        </div>
      )
    }

    const name = getKey(item.key)
    return (
      <Item label={item.title} name={name}>
        {
          (
            {
              [EQueryType.Input]: <Input allowClear {...baseOptions} {...aItem.itemProps} autoComplete="off" />,
              [EQueryType.Select]: renderSelect(aItem),
              [EQueryType.DatePicker]: <DatePicker allowClear {...aItem.itemProps} />,
              [EQueryType.DatePickerRange]: <RangePicker allowClear {...aItem.itemProps} />,
              [EQueryType.RangeInput]: <RangeInput />,
              [EQueryType.Cascader]: <Cascader allowClear {...aItem.itemProps} options={aItem.options} />,
              [EQueryType.RangeInputNumber]: <RangeInputNumber {...aItem.itemProps} />
            } as any
          )[item.type]
        }
      </Item>
    )
  }

  return !!cols.length ? (
    <div className={styles.component}>
      <Form form={form} labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }} onValuesChange={(_, allValues) => props.onChange?.(parseQueryFormValue(allValues))}>
        <Row gutter={gutter}>
          {cols.map((item, index) => {
            const { key, itemProps, span, ...cloProps } = item as any
            const colSpanPropp = span ? { span } : { xxl: 6, xl: 8 }

            return (
              <Col key={item.type + index} {...colSpanPropp} {...cloProps}>
                {renderItem(item)}
              </Col>
            )
          })}
        </Row>
      </Form>
    </div>
  ) : null
}

Component.defaultProps = {}
Component.displayName = 'Query'

const Query = memo(Component)
export default Query
