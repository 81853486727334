import { FC, memo } from 'react'
import TablePro from '../tablePro'
import Query from '../query'
import styles from './index.module.less'
import { ICompositionTableProps } from './const'

const Component: FC<ICompositionTableProps> = (props) => {
  const { renderHeader, query, onQuerySearch, onQueryOperationClick, queryParam, form, ...tableProps } = props
  const queryProps = { value: queryParam, query, onQuerySearch, onQueryOperationClick, form }

  console.log(queryParam, 'queryParam')
  return (
    <div className={styles.component}>
      <Query {...queryProps} />
      {renderHeader && <div className={styles.header}>{renderHeader}</div>}
      {tableProps.columns.length > 0 && <TablePro {...tableProps} />}
    </div>
  )
}

Component.displayName = 'CompositionTable'

const CompositionTable = memo(Component)
export default CompositionTable
