import { FC, memo, useEffect, useState, useContext, ReactNode } from 'react'
import styles from './index.module.less'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import globalContext from '~/contexts/global.context'
import { IMenus, ISubMenu } from '~/data-model/common'
import { getMenuSelectedAndOpenKey } from '~/utils/menu'

const { SubMenu } = Menu

function isISubMenu(data: ISubMenu | IMenus): data is ISubMenu {
  return (data as ISubMenu)?.children?.length > 0
}

const Component: FC = () => {
  const [openKeys, setopenKeys] = useState<string[]>([])
  const [selectedKeys, setselectedKeys] = useState<string[]>([])

  const location = useLocation()
  const context = useContext(globalContext)

  useEffect(() => {
    const result = getMenuSelectedAndOpenKey(context.menus, location.pathname)
    result.openKeys.length && setopenKeys(result.openKeys)
    result.selectedKeys.length && setselectedKeys(result.selectedKeys)
  }, [context.menus, location])

  const renderMenu = (data: (ISubMenu | IMenus)[], key = '') => {
    return data.map((item) => {
      const { iconImg, icon: Icon, title } = item
      const menuKey = key + title
      const iconNode: ReactNode = iconImg ? <img src={iconImg} className={styles.iconImg} /> : Icon ? <Icon style={{ fontSize: 20 }} /> : null

      return isISubMenu(item) ? (
        <SubMenu style={{ background: '#000' }} key={menuKey} title={title} icon={iconNode}>
          {renderMenu(item.children || [], menuKey)}
        </SubMenu>
      ) : (
        <Menu.Item key={item.path} title={title} icon={iconNode}>
          <Link to={item.path}>{title}</Link>
        </Menu.Item>
      )
    })
  }

  return (
    <Menu
      theme="dark"
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      mode="inline"
      style={{ background: '#000' }}
      className={styles.menuStyle}
      onClick={() => {
        localStorage.removeItem('historyParamsData')
      }}
      onOpenChange={(keys) => {
        console.log(keys, 'keys', selectedKeys, 'selectedKeys', openKeys, 'openKeys')
        setopenKeys(keys as any)
      }}
    >
      {renderMenu(context.menus)}
    </Menu>
  )
}

Component.displayName = 'SideMenus'

const SideMenus = memo(Component)
export default SideMenus
