import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.aboutUs,
    component: () => import('../../pages/operatingCopywriter/aboutUs/list'),
    meta: { title: '关于我们' }
  },
  {
    path: routeNames.privacyAgreement,
    component: () => import('../../pages/operatingCopywriter/privacyAgreement/list'),
    meta: { title: '隐私协议' }
  },
  {
    path: routeNames.communityRules,
    component: () => import('../../pages/operatingCopywriter/communityRules/list'),
    meta: { title: '社区规则' }
  },
  {
    path: routeNames.operationPaperworkQualityTesting,
    component: () => import('../../pages/operatingCopywriter/OperationPaperwork/QualityTesting'),
    meta: { title: '质检公示管理' }
  },
  {
    path: routeNames.operationPaperwork,
    component: () => import('../../pages/operatingCopywriter/OperationPaperwork/QualityTesting/Paperwork'),
    meta: {
      title: '质检公示',
      hideInMenu: true
    }
  },
  {
    path: routeNames.articleSort,
    component: () => import('../../pages/operatingCopywriter/articleSort'),
    meta: { title: '帖子排序设置' }
  }
] as TRoutes[]
