/**
 * 留言讨论管理
 */

import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.anchorMessageList,
    component: () => import('../../pages/messageManagement/anchorMessageList'),
    meta: { title: '主播留言' }
  },
  {
    path: routeNames.replyVideoList,
    component: () => import('../../pages/messageManagement/replyVideoList'),
    meta: { title: '回复视频' }
  },
  {
    path: routeNames.whiteList,
    component: () => import('../../pages/messageManagement/whiteList'),
    meta: { title: '回复白名单管理', hideInMenu: true }
  }
] as TRoutes[]
