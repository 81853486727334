
export enum EQueryType {
  /** 输入框 */
  Input = 'Input',
  /** 选择框 */
  Select = 'Select',
  /** 时间选择 */
  DatePicker = 'DatePicker',
  /** 时间范围选择 */
  DatePickerRange = 'DatePickerRange',
  /** 操作 */
  Operation = 'Operation',
  /** 搜索框 */
  SearchInput = 'SearchInput',
  /** 范围输入框 */
  RangeInput = 'RangeInput',
  /** 级连选择 */
  Cascader = 'Cascader',
  /** 数字范围输入框 */
  RangeInputNumber = 'RangeInputNumber',
}