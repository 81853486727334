import { FC, memo } from 'react'
import styles from './index.module.less'
import { IRangeInputNumberProps } from './const'
import { InputNumber, Space } from 'antd'
import { valueType } from 'antd/lib/statistic/utils'

const Component: FC<IRangeInputNumberProps> = (props) => {
  const { value = [], onChange, ...restProps } = props

  const handleChagne = (val: valueType, index: number) => {
    if (onChange) {
      const vals: any = [...value]
      vals[index] = val
      onChange(vals)
    }
  }

  return (
    <Space split={'-'}>
      <InputNumber {...restProps} value={value[0]} onChange={(ev) => handleChagne(ev || '', 0)} />
      <InputNumber {...restProps} value={value[1]} onChange={(ev) => handleChagne(ev || '', 1)} />
    </Space>
  )
}

Component.displayName = 'RangeInputNumber'

const RangeInputNumber = memo(Component)
export default RangeInputNumber
