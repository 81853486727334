import { atom, useAtom } from 'jotai'

/**
 * 消息通知数
 */
export const totalAtom = atom(0)
/**
 * 消息列表
 */
export const msgListAtom = atom([])
