/**
 * 全局上下文。在这里你可以存放一些全局的变量数据
 */
import React from 'react'
import { TMenu } from '~/data-model/common'

type Key = keyof Omit<IGlobalContext, 'dispatch'>
type Value = IGlobalContext[Key]

export interface IDispatch {
  (key: Key | Partial<Omit<IGlobalContext, 'dispatch'>>, value?: Value): void
}

export interface IGlobalContext {
  /** 用户名 */
  name: string
  /** 应用名称 */
  applicationName: string
  /** 菜单栏 */
  menus: TMenu[]
  /** 权限code */
  authCodes: string[]
  /** 数据修改 */
  dispatch: IDispatch
  /** 异步action */
  dispatchAction: (action: string, payload?: any) => Promise<any>
  /** 列表搜索记忆记录 */
  historyParamsData: any
  /**
   * 消息列表
   */
  msgList: any
  /**
   * 消息数量
   */
  total: number
}

const globalContext = React.createContext<IGlobalContext>({
  name: '',
  applicationName: '',
  historyParamsData: null,
  menus: [],
  authCodes: [],
  msgList: [],
  total: 0,
  dispatch() {},
  async dispatchAction() {}
})
export default globalContext
