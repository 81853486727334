import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  // {
  //   path: routeNames.departmentManagement,
  //   component: () => import('../../pages/departmentManagement'),
  //   meta: { title: '部门管理' }
  // },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/departmentManagement/employeeManagement'),
    meta: { title: '用户管理' }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/departmentManagement/roleManagement'),
    meta: { title: '角色管理' }
  },
  {
    path: routeNames.resourcesManagement,
    component: () => import('../../pages/departmentManagement/resourcesManagement'),
    meta: { title: '资源管理' }
  }
] as TRoutes[]
