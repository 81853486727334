
import dayjs from 'dayjs';
import { FC, memo } from 'react';
import { NCustomTable } from '../../const';
// import styles from './index.module.less';

export interface IProps extends NCustomTable.IColumnProps<NCustomTable.ITimeFormatColumn> { }

const Component: FC<IProps> = (props) => {
  const { column, value = '' } = props;
  return column.format ? dayjs(value).format(column.format) : value
}

Component.defaultProps = {};
Component.displayName = 'FormatTime';

const FormatTime = memo(Component);
export default FormatTime;