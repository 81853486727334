import { routeNames, TRoutes } from '~/routes/const'
const children: TRoutes[] = []
export default [
  // {
  //     path: routeNames.circleBanner,
  //     component: () => import('../../pages/circleManagement/settingBanner'),
  //     meta: {
  //         title: 'banner配置'
  //     }
  // },
  {
    path: routeNames.newsNotice,
    component: () => import('../../pages/circleManagement/newsNotice'),
    meta: {
      title: '消息通知'
    }
  },
  {
    path: routeNames.topicManagement,
    component: () => import('../../pages/circleManagement/topicManagement/list'),
    meta: {
      title: '话题管理'
    }
  },
  {
    path: routeNames.classifyManagement,
    component: () => import('../../pages/circleManagement/classifyManagement'),
    meta: {
      title: '圈子分类管理'
    }
  },
  {
    path: routeNames.circleManagement,
    component: () => import('../../pages/circleManagement/circleManagement'),
    meta: {
      title: '圈子管理'
    }
  },
  {
    path: routeNames.circleDetail,
    component: () => import('../../pages/circleManagement/circleManagement/circleDetail'),
    meta: {
      title: '圈子详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.circleAudit,
    component: () => import('../../pages/circleManagement/circleAudit'),
    meta: {
      title: '圈子审核'
    }
  },
  {
    path: routeNames.createCirclePermissionSettings,
    component: () => import('../../pages/circleManagement/createCirclePermissionSettings'),
    meta: {
      title: '创圈等级权限'
    }
  },
  {
    path: routeNames.whiteListAdministrator,
    component: () => import('../../pages/circleManagement/createCirclePermissionSettings/whiteListAdministrator'),
    meta: {
      title: '白名单圈主'
    }
  },
  {
    path: routeNames.postsManagement,
    component: () => import('../../pages/circleManagement/postsManagement'),
    meta: {
      title: '帖子管理'
    }
  },
  {
    path: routeNames.postsAudit,
    component: () => import('../../pages/circleManagement/postsAudit'),
    meta: {
      title: '帖子审核'
    }
  },
  {
    path: routeNames.postsAuditDetail,
    component: () => import('../../pages/circleManagement/postsAudit/postsDetail'),
    meta: {
      title: '帖子审核详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.postsDetail,
    component: () => import('../../pages/circleManagement/postsManagement/postsDetail'),
    meta: {
      title: '查看帖子',
      hideInMenu: true
    }
  },
  {
    path: routeNames.createPosts,
    component: () => import('../../pages/circleManagement/createPosts'),
    meta: {
      title: '发帖',
      hideInMenu: true
    }
  }
  // {
  //     path: routeNames.commentManagement,
  //     component: () => import('../../pages/circleManagement/commentManagement'),
  //     meta: {
  //         title: '评论管理'
  //     }
  // }
] as TRoutes[]
