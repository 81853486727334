import { LogoutOutlined, RightOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Menu, message, Space } from 'antd'
import { useContext, FC, memo, useCallback, useEffect, useState, useRef } from 'react'
import type { MenuInfo } from 'rc-menu/lib/interface'
import globalContext from '~/contexts/global.context'
// import styles from './index.module.less'
import { useHistory } from 'react-router-dom'
import { routeNames } from '~/routes/const'
import { IUserInfoProps } from './const'
import avatar from '~/assets/images/default_avatar.png'
import EditFormModalPage from '~/components/editFormModalPage'
import { useEditFormModal } from '~/components/editFormModal/hooks'
import { EFormProItem } from '~/modules/@xy/backend-pro/src/enums/EFormProItem'
import instance from '~/request/instance'
import { encryptPassword } from '~/pages/login/const'
import dayjs from 'dayjs'
import { atom, useAtom } from 'jotai'
import { msgListAtom, totalAtom } from '~/contexts/jotai.context'

const Component: FC<IUserInfoProps> = (props) => {
  const { name, dispatch } = useContext(globalContext)
  const history = useHistory()
  // 第一个值这里用不到，只要第二个值，更新函数
  const [total, setTotal] = useAtom(totalAtom)
  const [msgList, setMsgList] = useAtom(msgListAtom)

  const MAX_NUM = 5
  const TIME = 60 * 60 * 1000
  const savedCallback: any = useRef()
  const callback = () => {
    // 可以读取到最新的 state 和 props
    queryMsgNotice()
  }

  // 每次渲染，更新ref为最新的回调
  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current()
    }, TIME)
    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    queryMsgNotice()
  }, [])

  const queryMsgNotice = async () => {
    const { data = [] } = await instance.get('/console/msgNotice', { pageNum: 1, pageSize: MAX_NUM, readStatus: false })
    console.log('data', data)
    setTotal(() => data?.total)
    setMsgList(() => data?.list)
  }

  const checkMsgNotice = async (postItem) => {
    const { id, postsId } = postItem
    const { data = [] } = await instance.put(`/console/msgNotice/read/${id}`)
    queryMsgNotice()

    history.push({
      pathname: routeNames.postsAuditDetail,
      search: `id=${postsId}&postItem=${JSON.stringify(postItem)}`
    })
  }

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event
      if (key === 'editPassword') {
        editFormState.open()
        return
      }

      if (key === 'logout') {
        window.localStorage.removeItem('Authorization')
        dispatch({ name: '' })
        history.replace(routeNames.login)
        return
      }
    },
    [history]
  )

  //消息
  const menuNewsDropdown = (
    <Menu selectedKeys={[]}>
      {msgList?.map((item, index) => {
        return (
          <div key={item.id}>
            <Menu.Item
              key={item.id}
              onClick={() => {
                checkMsgNotice(item)
              }}
            >
              <div>用户{item.userName}给你的评论回复了消息</div>
              <div style={{ color: '#999', fontSize: '12px' }}>{dayjs(item.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}</div>
            </Menu.Item>
          </div>
        )
      })}
      {total >= MAX_NUM && (
        <Menu.Item
          key="more"
          onClick={() => {
            history.push({
              pathname: routeNames.newsNotice
            })
          }}
        >
          <div style={{ color: '#AB282D' }}>
            查看全部({total})<RightOutlined />
          </div>
        </Menu.Item>
      )}
    </Menu>
  )

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="editPassword">修改密码</Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  )

  const editFormState = useEditFormModal({
    items: [
      {
        type: EFormProItem.Input,
        label: '原密码',
        name: 'password',
        rules: [{ required: true }],
        componentProp: {
          placeholder: '请输入原密码',
          type: 'password'
        }
      },
      {
        type: EFormProItem.Input,
        label: '新密码',
        name: 'newPassword',
        rules: [{ required: true }],
        componentProp: {
          placeholder: '请输入新密码',
          type: 'password'
        }
      }
    ],
    submit: async (formData: Partial<any>, rawData: any) => {
      if (Array.isArray(formData.type)) {
        formData.type = formData.type.join(',')
      }

      const { password, newPassword } = formData

      const encryptedPassword = encryptPassword(password)
      const encryptedNewPassword = encryptPassword(newPassword)

      await instance.put('/console/console/sysUser/resetPwd', { password: encryptedPassword, newPassword: encryptedNewPassword })
      message.success('修改成功')
      return true
    }
  })

  return (
    <Space>
      {total > 0 ? (
        <Dropdown placement="bottomCenter" overlay={menuNewsDropdown}>
          <Space>
            <Badge dot>
              <span>消息通知 ({total})</span>
            </Badge>
          </Space>
        </Dropdown>
      ) : (
        <Space>
          <span>消息通知</span>
        </Space>
      )}

      <Dropdown overlay={menuHeaderDropdown}>
        <Space>
          <Avatar src={avatar} />
          <span>{name}, 您好</span>
        </Space>
      </Dropdown>
      <EditFormModalPage {...editFormState} title="修改密码" width={500} />
    </Space>
  )
}

Component.defaultProps = {}
Component.displayName = 'UserInfo'

const UserInfo = memo(Component)
export default UserInfo
