import { Table } from 'antd'
import Column from 'antd/lib/table/Column'
import { FC, memo } from 'react'
import { ITableProProps } from './const'
import styles from './index.module.less'
import useTableColumns from './hooks/useTableColumns'

const Component: FC<ITableProProps> = (props) => {
  const { columns, rowKey = 'id', dataSource = [], ...rest } = props
  const { getColumnProps } = useTableColumns(props)
  return (
    <Table {...rest} dataSource={dataSource} rowKey={rowKey} className={styles.component}>
      {columns?.map((column, index) => (
        <Column key={`${column.dataIndex || column.key || ''}-${index}`} {...getColumnProps(column)} />
      ))}
    </Table>
  )
}

Component.displayName = 'TablePro'

const TablePro = memo(Component)
export default TablePro
