import { message, Switch, SwitchProps } from 'antd'
import { FC, memo, useState } from 'react'
import { NCustomTable } from '../../const'
// import styles from './index.module.less';

export interface IProps<T = any> extends NCustomTable.IColumnProps<NCustomTable.ISwitchColumn<T>> {
  onSwitchChange(): void
}

/**
 * 表格开关组件
 *
 * 内置处理了点击切换时间
 * @param props
 * @returns
 */
const Component: FC<IProps> = (props) => {
  const { record, value, index, column } = props

  let options: SwitchProps = {}
  const checked = column.checked ? column.checked(value, record, index) : !!value
  if (column.componentProps) {
    options = column.componentProps(props)
  }
  options.checked = checked
  const { onChange, ...rest } = options

  const [loading, setLoading] = useState(false)

  const handleOnChange = async (checked: boolean, event: MouseEvent) => {
    if (column.onSwitch) {
      setLoading(true)
      try {
        await column.onSwitch({ record, value, index })
        onChange && onChange(checked, event)
        props.onSwitchChange && props.onSwitchChange()
      } catch (error) {
        // message.error('操作失败')
        console.error(error)
      }
      setLoading(false)
    }
  }

  return <Switch {...rest} loading={loading} onChange={handleOnChange} />
}

Component.defaultProps = {}
Component.displayName = 'SwitchColumn'

const SwitchColumn = memo(Component)
export default SwitchColumn
