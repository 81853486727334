
export enum ETableColumn {
  /** 无 */
  None = 'None',
  /** 操作栏 */
  Operation = 'Operation',
  /** 切换开关 */
  Switch = 'Switch',
  /** 图片 */
  Image = 'Image',
  /** 格式化日期 */
  TimeFormat = 'TimeFormat',
  /** 富文本 */
  RichText = 'RichText',
  /** 链接文本 */
  Link = 'Link',
}