import { Input, InputNumber, Select, Switch, DatePicker, Checkbox, Radio, Space, TreeSelect } from 'antd'
import { FC, memo } from 'react'
import { EFormProItem, EFormProItemType } from '../../../../enums/EFormProItem'
import ImgUpload from '../../../imgUpload'
import { FormProItemProps, TFormProItem } from './const'
import RichText from '../richText'
import styles from './index.module.less'
import { formatDate, formDataRange } from '../../../../utils/date'
import { Dayjs } from 'dayjs'
import LimitInput from '../limitInput'
import ColorPicker from '../../../colorPicker'
import IconSelect from '../iconSelect'
import CityPicker from '../../../cityPicker'
import SwitchPro from '../../../switchPro'
import VideoUpload from '../../../videoUpload'

const { TextArea } = Input
const { Option } = Select
const { Group: CheckboxGroup } = Checkbox
const { Group: RadioGroup } = Radio
const { RangePicker } = DatePicker

function getPlaceholder(type: EFormProItemType, text = '') {
  let placeholder: string | undefined = undefined
  if (type === EFormProItem.DatePickerRange) {
    return placeholder
  }
  placeholder = [EFormProItem.Select].includes(type as any) ? `请选择` : `请输入`
  placeholder += text
  return placeholder
}

const Component: FC<FormProItemProps> = (props) => {
  const { prefixText = '', suffixText = '', id, ...item } = props
  const { componentProp = {}, value, checked, onChange, ...rest } = item as any

  const renderItemCompoent = (item: TFormProItem) => {
    const { label, name, rules, type, ...baseProps } = rest
    const cProps = Object.assign(
      { placeholder: getPlaceholder(item.type, typeof item.label === 'string' ? item.label : '') },
      baseProps,
      componentProp,
      {
        id,
        onChange: (...args) => {
          onChange?.(...args)
          try {
            componentProp.onChange?.(...args)
          } catch (error) { }
        }
      },
      item.type === EFormProItem.Switch ? { checked } : { value }
    )

    const Component = {
      [EFormProItem.Input]: <Input {...cProps} />,
      [EFormProItem.InputNumber]: <InputNumber {...cProps} style={{ width: '100%' }} />,
      [EFormProItem.TextArea]: <TextArea {...cProps} />,
      [EFormProItem.Switch]: <SwitchPro {...cProps} />,
      [EFormProItem.Checkbox]: <CheckboxGroup {...cProps} />,
      [EFormProItem.Radio]: <RadioGroup {...cProps} />,
      [EFormProItem.RichText]: <RichText {...cProps} />,
      [EFormProItem.ImageUpload]: <ImgUpload {...cProps} />,
      [EFormProItem.LimitInput]: <LimitInput {...cProps} />,
      [EFormProItem.ColorPicker]: <ColorPicker {...cProps} />,
      [EFormProItem.IconSelect]: <IconSelect {...cProps} />,
      [EFormProItem.TreeSelect]: <TreeSelect {...cProps} />,
      [EFormProItem.CityPicker]: <CityPicker {...cProps} />,
      [EFormProItem.Mobile]: <LimitInput {...cProps} maxLength={11} />,
      [EFormProItem.Email]: <Input {...cProps} />,
      [EFormProItem.VideoUpload]: <VideoUpload {...cProps} />
    }[item.type]

    if (Component) {
      return Component
    }

    switch (item.type) {
      case EFormProItem.Custom:
        const Com = item.customRender
        return <Com {...cProps} />
      case EFormProItem.Select:
        // FIXED: 修复cProps.options覆盖item.options
        const { options, ...selectProps } = cProps
        return (
          <Select {...selectProps}>
            {item.options.map((opt) => (
              <Option {...(opt as any)} key={opt.value}>
                {opt.label || opt.title}
              </Option>
            ))}
          </Select>
        )
      case EFormProItem.DatePicker:
        cProps.value = formatDate(cProps.value)
        if (item.valueFormat && cProps.onChange) {
          const oldChange = cProps.onChange
          cProps.onChange = (value) => {
            if (!value) {
              return oldChange(undefined)
            }
            if (typeof item.valueFormat === 'string') {
              oldChange(value.format(item.valueFormat))
            } else if (item.valueFormat) {
              oldChange(item.valueFormat(value))
            }
          }
        }
        return <DatePicker {...cProps} />
      case EFormProItem.DatePickerRange:
        cProps.value = formDataRange(cProps.value)
        if (item.valueFormat && cProps.onChange) {
          const oldChange = cProps.onChange
          cProps.onChange = (value: Dayjs[] | null) => {
            if (!value || !value.length) {
              return oldChange([])
            }
            const { valueFormat = [] } = item
            if (valueFormat instanceof Array) {
              const [start, end = start] = valueFormat
              const fValue = value.map((date, idx) => (date ? date.format(idx === 0 ? start : end) : date))
              oldChange(fValue)
            } else {
              const fValue = value.map((date) => valueFormat(date))
              oldChange(fValue)
            }
          }
        }
        return <RangePicker {...cProps} style={{ width: '100%' }} />
    }
    return null
  }

  const isRenderSingle = !prefixText && !suffixText

  return isRenderSingle ? (
    <div className={styles.item}>{renderItemCompoent(item)}</div>
  ) : (
    <Space className={styles.component}>
      {!!prefixText && <span>{prefixText}</span>}
      <div className={styles.item}>{renderItemCompoent(item)}</div>
      {!!suffixText && <span>{suffixText}</span>}
    </Space>
  )
}

Component.displayName = 'FormProItemItem'

const FormProItemItem = memo(Component)
export default FormProItemItem
